@import "./variables.scss";

.Modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    
    .Modal__bg { 
        background-color: black;
        opacity: 0.8;
        position: relative;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    .Modal__loader-container { // Hack to override Loader component styles
        .Loader {
            height: auto;
            position: initial;
            border: 1px solid red;
            width: auto;
            display: block;
            z-index: 0;
        }
        .ripple {
            position: fixed;
            top: 50%;
            left: 50%;
        }
    }

    .Modal__content {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        justify-content: center;
        width: 80%;
        max-width: 960px;
    }

    .Modal__content img,
    .Modal__content p {
        width: 100%;
    }

    .Modal__caption {
        @include font--small;
        margin-top: 0.5rem;
    }

    .Modal__video iframe {
        position: relative;
        left: 50%;
        transform: translate(-50%);

        @media (max-width: 640px ) {
            max-width: 320px;
        }
    }
}