@import "../../variables.scss";

.CoverPinterest {
    background-color: #ca0014;


    .container-inner {
        padding: 6rem 0;
    }
}


// Temp: Relative sizing =======

// .CoverPinterest {
//     background-color: #e60023;
//     background-size: 100% 56.25%;

//     position: relative;
//     width: 100%;
//     padding-top: 56.25%;

//     .container-inner {
//         @include contained;

//         position: absolute;
//         top: 0;
//         left: 0;
//         bottom: 0;
//         right: 0;

//         display: flex;
//         align-items: center;
//         justify-content: center;

//         padding: 0 100px;
//     }
// }
