@import "variables.scss";

// code from: https://codepen.io/stavros-liaskos/pen/GGRjVZ?editors=1100 and https://codepen.io/jbwharris/pen/dBZKxv
// discussion: https://github.com/rachelandrew/cssgrid-ama/issues/127

// no IE support!
$grid_gutter: 30px;

// Grid array (used for generating the grid)
$grid_arr: (
    (xs, $screen_viewport_xs), 
    (sm, $screen_viewport_sm), 
    (md, $screen_viewport_md), 
    (lg, $screen_viewport_lg), 
    (xl, $screen_viewport_xl)
);

.row {
    // border: 1px solid black; //debugging
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    width:100%;

    column-gap: $grid_gutter; //change property from "column-gap" to "grid-gap" if you want vertical margin between rows
    @media (min-width: $screen_viewport_sm) { column-gap: calc(#{$grid_gutter}); }
    @media (min-width: $screen_viewport_md) { column-gap: calc(#{$grid_gutter}) !important; }
}

[class^="col-"], [class*=" col-"] { grid-column-start: span 12; }

@each $label, $breakpoint, $width in $grid_arr {
    @media (min-width: #{$breakpoint}) {
        @for $i from 1 through 12 {
            @for $y from 1 through 12 {
                // Differs from bootstrap from the point that it specifies the index of a column to start
                // example: start-6 col-4 (start at col 6 columns and occupy 4 columns
                $combined : $i + $y;
                @if($combined <= 12) {
                    .start-#{$label}-#{$y}.col-#{$label}-#{$i} {
                        grid-column: #{$y + 1} / span #{$i};
                    }
                }
            }

            // as in bootstrap
            .col-#{$label}-#{$i} {
                // border: 1px solid black; //debugging
                grid-column-start: span #{$i};
                // text-overflow: clip;
            }
        }
  }
}

.container {
    // border: 1px solid black; //debugging

    width: calc(100% - ( #{$grid_gutter} * 1.5 ));
    @media (min-width: $screen_viewport_sm) { width: calc(100% - ( #{$grid_gutter} * 3)); }
    @media (min-width: $screen_viewport_md) { width: calc(100% - ( #{$grid_gutter} * 4)); }

    margin: 0 auto;
    max-width: $screen_viewport_xl;
}

// prevent margin effect on nested grids
.container .container {
    width: 100%;
}

// swaps
.row--swap--1-2 {
    @media (max-width: $screen_viewport_md) { 
        .col--swap:nth-child(1) { order: 2 !important; }
        .col--swap:nth-child(2) { order: 1 !important; }
    }
}