.loader-content-container {
    position: relative;
}

.Loader {
    height: 100%;
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: -1;
    
    .ripple {
        margin: 0 auto;

        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        border: 0.3rem solid grey;
        animation: 0.75s ripple ease-out infinite;
      }

    @keyframes ripple {
        from {
            transform: scale(0);
            opacity: 1;
        }
        
        to {
            transform: scale(1);
            opacity: 0;
        }
    }
}
