@import "variables.scss";

.CoverHeader {
    @media (max-width: $screen_viewport_sm) {
        padding-bottom: 1rem;
        padding-top: 1rem;
    }

    padding-bottom: 1rem;
    padding-top: 1rem;

    .cover__header__title {
        font-weight: bold;
    }

    .cover__header__description {
        @media (max-width: $screen_viewport_sm) {
            text-align: left;
        }

        text-align: right;
    }
  
}
  