@import "../../variables.scss";

.CoverBeatles {
    background-color: #1E1E1A;

    .container-inner {
        padding: 3rem 0;
        max-height: 800px;
        height: 100%;
    }

    .container-inner img {
        max-height: 800px;
        object-fit: contain;
    }
}