@import "variables.scss";


.Work {
    margin-bottom: 8rem;
    margin-top: 7rem;

    a {
        text-decoration: none;
    }

    a:hover {
        color: $color--text;
        // text-decoration: underline;
    }

    .cover {
        @media (max-width: $screen_viewport_sm) {
            margin-bottom: 4rem;
        }

        margin-bottom: 7rem;
    }

    .cover__header {
        background-color: white;
        position: -webkit-sticky;
        position: sticky;
        top: -1px;
        z-index: 1;
    }

    .cover__header--fixed-top {
        color: red;
        position: absolute;
        top: 0;
        transition: 1s;
    }

    //to put in a cover-specific style scope?
    .cover__video {
        width: 100%;
    }

    .temp-caption {
        padding-top: 0.8rem;
        @include font--small;
        position: relative;
    }

    .temp-caption__caption {
        // position: absolute;
    }

    .temp-caption a {
        text-decoration: underline;
    }
    

  }