@import "../../variables.scss";

.CoverVcb {

}

.Vcb {
    .VcbThumb {
        margin-bottom: 2rem;
    }

    .VcbThumb:hover {
        text-decoration: underline;
        cursor: pointer;
    }

    .VcbThumb__img {
        margin-bottom: 0.25rem;
    }

    .VcbThumb__artist {
        font-weight: bold;
    }
}