@import "../../variables.scss";

$color--trusat-blue-dark: #0d1220;
$color--trusat-blue-medium: #112b49;
$color--trusat-salmon: #eb7e5f;

.CoverTrusat {

    margin-top: -1.25rem;

    .trusat__cover-offsetter {
        @media (min-width: $screen_viewport_md) { 
            width: 105%;
        }
    }

    .trusat__cover-caption-spacer {
        margin-top: -3rem;
        margin-bottom: 1rem;

        @media (min-width: $screen_viewport_md) { 
            margin-top: -5rem;
            margin-bottom: 0;
        }
    }
 
}

.Trusat {

    .project__section-title {
        margin-bottom: 0.5rem !important;
    }

    .trusat__section__title {
        // TEMP
        height: 10rem; 


        display : flex;
        align-items : center;
        justify-content: center;

        background-color: $color--trusat-blue-dark;
        color: $color--background;
        @include font--xxxlarge;
    }

    .trusat__section__title--top {

    }

    h4 {
        font-weight: bold;
        margin-bottom: 0.25rem;
    }

    .trusat__highlight {
        color: $color--trusat-salmon;
    }

    .trusat__section__body {
        // padding-top: 4rem;
        padding-top: 6rem;
    }

    .trusat__subsection {
        margin-bottom: 6rem;
    }


    .trusat__subsubsection {
        margin-bottom: 4rem;
    }

    .trusat__subsubsection--problem {
        margin-bottom: 2rem;
    }

    .video-wrapper {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        border: 1px solid green;
    }

    .trusat__vid-screenshot {
        margin-bottom: 2rem;
    }

    .trusat__video > div {
        position: absolute; // Scaling will occur since parent is relative now
      }

    .trusat__avatar {
        @media (max-width: $screen_viewport_md) { 
            text-align: center;
            margin-bottom: 2rem;
            &:first-child {
                margin-top: 1rem;
            }
        }
    }

    .trusat__avatar h4, 
    .trusat__avatar p {
        @media (max-width: $screen_viewport_md) { 
            text-align: left;
        }
    }

    .trusat__avatar img {
        max-width: 240px;
    }

    .trusat__section--research .trusat__section__body {
        padding-top: 0;
    }

    .trusat__section--blue {
        color: white;
        background-color: $color--trusat-blue-medium;
        
        padding-top: 6rem;
        padding-bottom: 1rem;

        a,
        a:active,
        a:visited,
        a:hover {
            color: white;
        }
    }

    .trusat__section--research1 {
        margin-top: -0.5rem;
        margin-bottom: 6rem;
    }

    .trusat__section--bottom {
        margin-bottom: 0;
    }

    .trusat__personas h4 {
        margin-bottom: 0.25rem;
    }

    ol {
        list-style: none;
        counter-reset: item;
    }

    ol > li {
        counter-increment: item;
        margin-bottom: 0.75rem;
    }

    ol > li:before {
        margin-right: 0.33rem;
        content: counter(item);
        background: $color--text;
        border-radius: 50%;
        color: $color--background;
        font-size: $font-size--small;
        line-height: 1.25rem;
        width: 1.25rem;
        height: 1.25rem;
        position: relative;
        top: -0.125rem;
        text-align: center;
        display: inline-block;
    }

    .trusat__spectrum-group {
        margin-bottom: 1rem;
    }

    .trusat__video-screenshot {
        margin-bottom: 1rem;
        @media (max-width: $screen_viewport_md) { 
            // margin-bottom: 3rem;
        }
    }

    .trusat__video-screenshot-caption {
        position: absolute;
    }
}