@import "variables.scss";


.Home {

    .home__header {
        @media (max-width: $screen-viewport-md) {
            padding-top: 2rem;
        }

        padding-top: 2.5rem;
    }

    .home__header__img {
        width: 300px;
        margin-left: -3px;
        padding-bottom: 1.2rem;
    }

    .home__header__about-link-container {
        @media (max-width: $screen-viewport-md) {
            text-align: left;
            margin-top: 0.5rem;
            text-decoration: underline;
        }
        text-align: right;
        
        a {
            text-decoration: none;
        }
    }


  }

