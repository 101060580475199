@import "../../variables.scss";

/////// WHAT ABOUT THE RALWAY FONT? 
/////// WOULD BE HANDY TO KNOW HOW TO IMPORT A FONT JUST IF YOU LOAD THIS PAGE ///

$color-umbro-paper: #fff5e3;
$color-umbro-pass: #fdc659;
$color-umbro-shot: #333132;
$color-umbro-goal: #ef3e23;

.CoverUmbro {
    background-color: $color-umbro-paper;

    .container-inner {
        padding: 2rem 0;
    }
}

#Umbro {

	// * INTRO OVERRIDES ********

	.project__title {
		color: $color-umbro-goal
	}
	
	.project__intro-wrapper {
		background-color: $color-umbro-shot;
		color: $color-umbro-paper;
		margin-bottom: 6rem;
	}

	.project__intro-wrapper a {
		color: $color-umbro-paper;
	}

	// * COLORS ********

    background-color: $color-umbro-paper;
	color: $color-umbro-shot;

	// *TYPOGRAPHY ************

	@media (max-width: $screen_viewport_md) {
		.project__content .container {
			width: 100%;
		}
	}

	.project__content {
		text-align: center;
	}

	// section title
	h3 { 
        text-transform: uppercase;
		font-size: 2rem;
		line-height: 2.25rem;
        color: $color-umbro-goal;
		font-weight: 700;
	}
	
	.section--onboarding h3 {
		margin-bottom: 1rem;
	}

	.section--tournament h3 {
		margin-bottom: 4rem;
	}

	// subsection title
	h5 {
        text-transform: uppercase;
		font-size: 1rem;
		margin-bottom: 0.5rem;
        font-weight: 700;
	}
		
	// timeline description
	h6 {
		font-size: 0.8rem;
		padding: 0 1.5rem;
	}
	

	// SPACING *************


	.section-title__title {
		margin-bottom: 0.5rem;
	}

	.graphic-container--onboarding {
        margin-bottom: 3rem;
	}
	
	.section-break {
		background-color: $color-umbro-shot;
		height: 4rem;
		margin-bottom: 6rem;
	}

	.section {
		margin-bottom: 4rem;

		@media (max-width: $screen_viewport_md) {
			margin-bottom: 3rem;
		}
	}

	.subsection {
		margin-bottom: 3rem;
	}

	.subsection--semifinal {
		margin-bottom: 2rem;

		@media (max-width: $screen_viewport_md) {
			margin-bottom: 3rem;
		}
	}

	.sub-subsection {
		margin-bottom: 1rem;
	}

}

