@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
@import "variables.scss";
@import 'Grid.scss';

// Typography
html {
  color: $color--text;
  font-family: Poppins, Helvetica, Arial, sans-serif;
  @include font--regular;
}

strong {
  font-weight: 700;
}

em {
  font-style: italic;
}

a,
a:active,
a:visited {
  color: $color--text;
}

a:hover {
  color: $color--highlight;
}

p {
  margin-bottom: 0.75rem;
}

.note,
p .note,
.note p {
  display: block;
  margin-bottom: 0.5rem;
}

.nobr {
  white-space: nowrap;
}

// Responsive hides
.hide-on-mobile {
  display: none;

  @media (min-width: $screen_viewport_md) {
    display: block;
  }
}
.hide-till-desktop {
  display: none;

  @media (min-width: $screen_viewport_lg) {
    display: block;
  }
}
.hide-on-desktop {
  display: block;

  @media (min-width: $screen_viewport_md) {
    display: none;
  }
}

// Images
img {
  width: 100%;
  margin: 0;
}


// Buttons

button {
  border: 1px solid $color--text;

  @media (min-width: $screen_viewport_md) {
    border: 2px solid $color--text;
  }

  color: $color--text;
  padding: 0 1.5rem;
  height: 3rem;
  outline: none;
  cursor: pointer;
  background: none;
  transition: .25s;
  font-size: 1rem;
  border-radius: 5px;
  &:hover{
    border: none;
    background-color: $color--text;
    transition: all 0.125s ease-in-out;
    color: $color--background;
    font-size: 1.1rem;
    transform:scale(1.1);
  }
}


// Other
.zneg1 {
  z-index: -1 !important;
}

.z1 {
  z-index: 1;
}

.video-snack {
  width: 100%;
}

div {
  // border: 1px solid red !important;
}