@import "variables.scss";

.ProjectHeader {
    background-color: white;
    
    padding-top: 1rem;
    padding-bottom: 1rem;

    a {
      text-decoration: none;
  }

    .header__home-link {
      width: 4rem;
      position: relative;
      top: 0.3rem;
    }
  
    .header__about-links {
      text-align: right;
    }
  
  }
  