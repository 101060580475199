@import "./../variables.scss";


.About {

    // General =================== 
    @media (max-width: $screen_viewport_sm) {
        padding-bottom: 0;
    }

    padding-bottom: 2rem;
    padding-top: 2rem;

    background-color: black;
    color: white;

    a {
        color: white;
        &:hover {
            color: $color--highlight;
        }
    }

    .about__header {
        text-align: right;
        padding: 20px 0 40px;
        
    }

    .about__work-link {
        text-decoration: none;
    }

    .about__work-link__icon {
        position: relative;
        top: 0.125rem;

    }

    .about__footer {
        padding: 20px 0 40px;;
    }

    .about__section {
        margin-bottom: 4rem;
    }

    .about__section__title {
        font-weight: bold;
        padding-bottom: 0.66rem;
        margin-bottom: 1rem;
        border-bottom: 1px solid white;
    }

    .about__back-to-top {
        @media (max-width: $screen_viewport_sm) {
            text-align: left;
            margin-top: 4rem;
        }
        text-align: right;

        a {
            text-decoration: none;
        }
    }


    // Contact ===================

    .about__section--contact .about__section__content{
        // @include font--large;
        a {
            text-decoration: none;

        }
    }

    .contact-icon-container {
        text-align: right;
    }

    .contact-icon {
        font-size: 30px;
        position: relative;
        top: 0.25rem;
        display: inline;
        margin-right: 1.5rem;
    }


    // Bio ===================

    .about__section--bio .about__section__content {
        @media (max-width: $screen_viewport_sm) {
            @include font--large;
        }

        @include font--xlarge;
        font-weight: 100;
    }

    // Services ===================
    
    .about__section--services .about__section__content {
        // @media (max-width: $screen_viewport_sm) {
        //     @include font--large;
        // }

        @include font--large;
        font-weight: 100;
    }
  
    .about__section--services .about__section__content ul li{
        margin-bottom: 0.66rem;
    }

    // Clients ===================

    .about__section--clients .about__section__content {
        // line-height: 1.15;
        // @include font--regular;
        @include font--large;
    }
  
    .about__section--clients .about__section__content ul li{
        margin-bottom: 0.5rem;
    }

    // Recognition ===================

    .about__section--recognition .about__section__content {
        // @include font--regular; // This is getting overridden for some reason, so I'm hard-coding the mixin:
        font-size: calc(#{$font-size--regular}*.8);
        line-height: calc(#{$line-height--regular}*.8);

        @media (min-width: $screen_viewport_sm) {
            @include font--small;
        }
    }

    .about__section--recognition .about__section__content ul {
        margin-bottom: 1rem;
    }

    .about__section--recognition .about__section__content ul li:first-child {
        font-weight: bold;
    }

    .about__section--recognition .about__section__content ul li {
        margin-bottom: 0.25rem;
    }

    .about__modal-links li:not(:first-child) {
        cursor: pointer;
        &:hover {
            color: $color--highlight;
        }
    }
  }