@import "../../variables.scss";

$color-bnews-text: white;
$color-bnews-bg: #2a2a2e;

.CoverBeautifulNews {

}

.BeautifulNews {
    color: $color-bnews-text;
    background-color: $color-bnews-bg;

    a,
    a:visited,
    a:active,
    a:hover {
       color: $color-bnews-text; 
    }

    div {
        // border: 1px solid red;
    }

    .container--bnews {
        .row {
            column-gap: 6px !important; 
        }

        img {
            margin-bottom: -2px;

            @media (max-width: $screen_viewport_md) { 
            margin-bottom: 1.25rem;
            }
        }
    }

    .project__body {
        margin-bottom: 5rem;
    }
}
