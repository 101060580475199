@import "../../variables.scss";

.CoverWhales {
    background-color: #0F1629;

    .container-inner {
        padding: 3rem 0;
    }


}

.Whales {
    // .whales-iframe {
    //     width: 100%;
    //     // margin-top: -30px;
    //     height: 100%;
    // }
}