$chromeHeight: 20px;

.browser-mock {
  box-shadow:
  0 4.9px 2.9px rgba(0, 0, 0, 0.05),
  0 13.5px 8.1px rgba(0, 0, 0, 0.07),
  0 32.6px 19.6px rgba(0, 0, 0, 0.09)
  ;
}

.browser-mock img {
  margin-bottom: 0 !important;
  display: block;
}

.browser-mock__chrome {
  position: relative;
}

.browser-mock__chrome--left,
.browser-mock__chrome--right,
.browser-mock__chrome--middle {
  background-color: #E2E2E2;
  height: $chromeHeight;
}


.browser-mock__chrome--left,
.browser-mock__chrome--right {
  width: $chromeHeight;
  position: absolute;
  top: 0;
}

.browser-mock__chrome--left {
  left: 0;
  border-radius: calc(#{$chromeHeight}/4) 0 0 0;
  -moz-border-radius: calc(#{$chromeHeight}/4) 0 0 0;
  -webkit-border-radius: calc(#{$chromeHeight}/4) 0 0 0;
}

.browser-mock__chrome--middle {
  margin: 0 $chromeHeight;
}

.browser-mock__chrome--right {
  right: 0;
  border-radius: 0 calc(#{$chromeHeight}/4) 0 0;
  -moz-border-radius: 0 calc(#{$chromeHeight}/4) 0 0;
  -webkit-border-radius: 0 calc(#{$chromeHeight}/4) 0 0;
}

.browser-mock__chrome--button {
  width: calc(#{$chromeHeight}/2);
  height: calc(#{$chromeHeight}/2);
  background-color: #adadad;
  border-radius: $chromeHeight;
  -moz-border-radius: $chromeHeight;
  -webkit-border-radius: $chromeHeight;
  margin: calc(#{$chromeHeight}/4);
}
