@import "../../variables.scss";

.CoverObamaLegacy {
}

.Obama {

    .obama-section {
        border-top: 2px solid $color--text;
        padding-top: 3rem;

        &:first-child {
            border-top: 0;
            padding-top: 0;
        }

        &:hover {
            cursor: pointer;
        }
    }
    .obama-thumb--cover {
        margin-bottom: 1rem;
    }

    .play-button-container {
        padding-top: 1rem;
        text-align: center;
    }
}