$screen_viewport_xs: 0;
$screen_viewport_sm: 500px;
$screen_viewport_md: 900px;  //previously 768
$screen_viewport_lg: 1280px;  //previously 992
$screen_viewport_xl: 1440px;  //previously 1260

$color--text: #1F1F1F;
$color--background: white;
$color--highlight: red;

$font-size--small: 0.6666rem; //16px
$font-size--regular: 24px;
$font-size--large: 1.5rem;
$font-size--xlarge: 2rem; //48px
$font-size--xxxlarge: 3rem; //72px
$font-size--xxxlarge--mobile: 2rem; //72px

$line-height--small: 1.33;
$line-height--regular: 1.33;
$line-height--large: 1.2;
$line-height--xlarge: 1.2;
$line-height--xxxlarge: 1.15;

@mixin font--small {
    font-size: $font-size--small;
    line-height: $line-height--small;
}

@mixin font--regular {
    font-size: $font-size--regular;
    line-height: $line-height--regular;

    @media (max-width: $screen_viewport_md) {
        // NOTE: This change affects the global REM unit. Changing this affects spacing and sizing on every page.
        font-size: 1rem;
    }
}

@mixin font--large {
    font-size: $font-size--large;
    line-height: $line-height--large;
}

@mixin font--xlarge {
    font-size: $font-size--xlarge;
    line-height: $line-height--xlarge;
}

@mixin font--xxxlarge {
    font-size: $font-size--xxxlarge;
    line-height: $line-height--xxxlarge;
}

