@import "variables.scss";

.ProjectFooter {
    padding-top: 4rem;
    padding-bottom: 4rem;

    background-color: $color--text;
    color: $color--background;

    @include font--xlarge;

    a {
        color: $color--background;
    }

    a:hover {
        color: $color--highlight;
        //why is this line necessary? Shouldn't it catch the style from the global a:hover ?
    }

  }
  